var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-breadcrumbs',{staticClass:"pa-0 pb-2",attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-12",attrs:{"cols":"auto"}},[_c('h1',[_vm._v("Orders")])]),_c('v-col',{staticClass:"pl-12"},[_c('v-text-field',{attrs:{"label":"Search Orders","outlined":"","dense":"","hide-details":"","prepend-inner-icon":"mdi-magnify"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-row',{attrs:{"align":"center","justify":"end","no-gutters":""}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.orders,"no-data-text":"No Orders can be found"},scopedSlots:_vm._u([{key:"item.id",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
            name: 'module-paw-orders-individual',
            params: { orderId: item.id },
          }}},[_vm._v("#"+_vm._s(item.identifier)+" "+_vm._s(item.customer.full_name))])]}},{key:"item.created_at",fn:function({ item }){return [_vm._v(" "+_vm._s(item.formatted_dates.created_at)+" ")]}},{key:"item.status",fn:function({ item }){return [(item.status === 'processing')?_c('v-chip',{attrs:{"label":"","small":"","color":"blue","text-color":"white"}},[_vm._v("Processing")]):(item.status === 'pending-payment')?_c('v-chip',{attrs:{"small":"","label":"","color":"warning"}},[_vm._v("Pending Payment")]):(item.status === 'cancelled')?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("Cancelled")]):(item.status === 'complete')?_c('v-chip',{attrs:{"label":"","small":"","color":"success"}},[_vm._v("Complete")]):_vm._e()]}},{key:"item.total",fn:function({ item }){return [_vm._v("£"+_vm._s(item.total))]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"x-small":"","depressed":"","color":"green lighten-4 green--text","to":{
            name: 'module-paw-orders-individual',
            params: { orderId: item.id },
          }}},[_c('v-icon',{attrs:{"x-small":"","left":""}},[_vm._v("mdi-eye")]),_vm._v(" View ")],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }