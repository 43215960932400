<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Orders</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Orders"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters> </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="orders"
        no-data-text="No Orders can be found"
      >
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{
              name: 'module-paw-orders-individual',
              params: { orderId: item.id },
            }"
            >#{{ item.identifier }} {{ item.customer.full_name }}</router-link
          >
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.formatted_dates.created_at }}
        </template>

        <template v-slot:item.status="{ item }">
          <v-chip
            label
            small
            v-if="item.status === 'processing'"
            color="blue"
            text-color="white"
            >Processing</v-chip
          >
          <v-chip
            small
            label
            v-else-if="item.status === 'pending-payment'"
            color="warning"
            >Pending Payment</v-chip
          >
          <v-chip label small v-else-if="item.status === 'cancelled'"
            >Cancelled</v-chip
          >
          <v-chip
            label
            small
            v-else-if="item.status === 'complete'"
            color="success"
            >Complete</v-chip
          >
        </template>
        <template v-slot:item.total="{ item }">£{{ item.total }}</template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            x-small
            depressed
            color="green lighten-4 green--text"
            :to="{
              name: 'module-paw-orders-individual',
              params: { orderId: item.id },
            }"
          >
            <v-icon x-small left>mdi-eye</v-icon> View
          </v-btn>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Paw Dog Food",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Orders",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Order", value: "id" },
        {
          text: "Date Placed",
          value: "created_at",
        },
        { text: "Status", value: "status" },
        { text: "Total", value: "total" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    orders() {
      let orders = this.$store.state.paw["orders"];

      if (this.searchTerm !== "") {
        orders = orders.filter((o) => {
          const index = String(o.id).toLowerCase();
          const name = o.customer.full_name.toLowerCase();
          const email = o.customer.email.toLowerCase();
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            index.includes(this.searchTerm.toLowerCase()) ||
            email.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return orders;
    },
  },

  methods: {
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "created_at") {
          if (!isDesc[0]) {
            return new Date(b[index]) - new Date(a[index]);
          } else {
            return new Date(a[index]) - new Date(b[index]);
          }
        } else {
          if (typeof a[index] !== "undefined") {
            if (!isDesc[0]) {
              return a[index]
                .toLowerCase()
                .localeCompare(b[index].toLowerCase());
            } else {
              return b[index]
                .toLowerCase()
                .localeCompare(a[index].toLowerCase());
            }
          }
        }
      });
      return items;
    },
  },
};
</script>
